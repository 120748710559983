// import request from '@/utils/request'

// // 获取城市
// export const City = (params) => {
//     return request('city.php', 'get', params)
// }

// // 地方名品
// export const Goods = (params) => {
//     return request('goods.php', 'get', params)
// }

// // 文旅资讯
// export const info = (params) => {
//     return request('info.php', 'get', params)
// }

// // 文旅景点
// export const scenic = (params) => {
//     return request('scenic.php', 'get', params)
// }

// // 城市产业展区
// export const company = (params) => {
//     return request('company.php', 'get', params)
// }

// // 税率查询
// export const taxSearch = (params) => {
//     return request('https://rcep.jinzhou-rcep.com/shiwucheng/api/handler/shui.php', 'get', params)
// }


import request from '@/utils/request'


// 获取banner
export const Banner = (params) => {
    return request('banners.php', 'get', params)
}

// 获取区县级
export const area = (params) => {
    return request('area.php', 'get', params)
}

// 获取城市信息
export const City = (params) => {
    return request('city.php', 'get', params)
}

// 获取城市采购
export const cityBuy = (params) => {
    return request('city_buy.php', 'get', params)
}
// 获取城市特产
export const citySpecial = (params) => {
    return request('city_special.php', 'get', params)
}

// 地方名品
export const Goods = (params) => {
    return request('goods.php', 'get', params)
}

// 文旅资讯
export const info = (params) => {
    return request('place_text.php', 'get', params)
}

// 文旅景点
export const scenic = (params) => {
    return request('place.php', 'get', params)
}

// 企业信息
export const company = (params) => {
    return request('company.php', 'get', params)
}
// 企业类型
export const componyType = (params) => {
    return request('company_type.php', 'get', params)
}

// 时政要闻
export const politics = (params) => {
    return request('politics.php', 'get', params)
}


// 税率查询 
export const taxSearch = (params) => {
    // return request('https://rcep.jinzhou-rcep.com/shiwucheng/api/handler/shui.php', 'get', params)
    return request('shui.php', 'get', params)
}

// 合作洽谈
export const askCooperate = (params) => {
    return request('ask.php', 'post', params)
}