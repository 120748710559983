// 城市模块
import { City, } from '@/api/index'

export default {
    namespaced: true,
    state() {
        return {
            citysIndex: 0,
            citysList: []
        }
    },
    actions: {
        setCitysIndexByCity(state, val) {
            state.state.citysList.forEach((obj, index) => {
                if (obj.name == val) {
                    state.commit('setCitysIndex', index)
                }
            })
        },
        getCitys(state, val) {
            City({
                controller: 'readCard',
            }).then(res => {
                state.commit('setCitys', res.data.data)
            })
        }
    },
    mutations: {
        setCitysIndex(state, val) {
            state.citysIndex = val
        },
        setCitys(state, val) {
            state.citysList = val
        },
    }
}