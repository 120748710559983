import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import language from './modules/language'
import cate from './modules/cate'
import citys_cate from './modules/citys_cate'
import citys from './modules/citys'

export default createStore({
    modules: {
        language,
        cate,
        citys_cate,
        citys
    },
    // 配置插件
    plugins: [
        // 默认存储在localStorage
        createPersistedState({
            // 本地存储名字
            key: 'la-website-store',
            // 指定需要存储的模块
            paths: ['language', 'cate', 'citys_cate', 'citys']
        })
    ]
})
