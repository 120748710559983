// 用户模块
export default {
    namespaced: true,
    state() {
        return {
            language: 'c',
        }
    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },
    }
}