// 用户模块
import router from "@/router"
export default {
    namespaced: true,
    state() {
        return {
            navListIndex: 0,
            navList: [
                {
                    link: '/',
                    name_c: '首页',
                    query: {
                    }
                },
                {
                    link: '/news',
                    name_c: '时政要闻',
                    query: {
                        type: 'news'
                    }
                },
                {
                    link: '/',
                    name_c: '跨境出口',
                    query: {
                        type: 1,
                    }
                },
                {
                    link: '/',
                    name_c: '跨境进口',
                    query: {
                        type: 2
                    }
                },
                {
                    link: '/citys',
                    name_c: '城市馆',
                    query: {
                        type: 'citys'
                    }
                },
                {
                    link: '/',
                    name_c: 'RCEP综合服务',
                    query: {
                        type: 3
                    }
                },
                {
                    link: '/tax',
                    name_c: '税率查询',
                    query: {
                        type: 'tax'
                    }
                }
            ]
        }
    },
    mutations: {
        setNavListIndex(state, index) {
            state.navListIndex = index
            router.push({
                path: state.navList[index].link,
                query: state.navList[index].query
            })

        },
    }
}