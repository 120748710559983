import _objectSpread from "E:/1-\u5DE5\u4F5C\u5907\u4EFD/5-\u9879\u76EE\u7BA1\u7406/18-\u5BC6\u8BC4\u9879\u76EE/0-\u9996\u9875\u9879\u76EE/code_shiwucheng/official_website/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { ElBacktop } from 'element-plus';

// 组件
import FooterVue from '@/components/la_footer.vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  components: {
    FooterVue: FooterVue,
    ElBacktop: ElBacktop
  },
  setup: function setup() {
    var router = useRouter();
    var data = reactive({
      isFooter: true
    });
    watch(function () {
      return router.currentRoute.value.path;
    }, function (newVal) {
      if (newVal == '/tax') {
        data.isFooter = false;
      } else {
        data.isFooter = true;
      }
    }, {
      immediate: true
    });
    return _objectSpread({}, toRefs(data));
  }
});