import _defineProperty from "E:/1-\u5DE5\u4F5C\u5907\u4EFD/5-\u9879\u76EE\u7BA1\u7406/18-\u5BC6\u8BC4\u9879\u76EE/0-\u9996\u9875\u9879\u76EE/code_shiwucheng/official_website/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import 'element-plus/theme-chalk/el-loading.css';
// export const baseURL = 'https://rcep.jinzhou-rcep.com/web_api/handler/'
export var baseURL = 'https://rcep.jinzhou-rcep.com/shiwucheng/api/handler/';
var loading;
// Loading的option
var options = {
  text: '载入中...',
  background: 'rgba(255,255,255,0.6)',
  spinner: 'el-icon-loading',
  lock: true
};
var instance = axios.create({
  baseURL: baseURL,
  timeout: 60000
});

// 请求拦截器
instance.interceptors.request.use(function (config) {
  loading = ElLoading.service(options);
  // const { profile } = store.state.user
  // if (profile.token) {
  //     config.headers.Authorization = `Bearer ${profile.token}`
  // }

  if (config.url.indexOf('https') != -1) {
    config.baseURL = '';
  }
  config.headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept': 'application/json;charset=UTF-8'
  };
  return config;
}, function (err) {
  return Promise.reject(err);
});

// 响应拦截器
instance.interceptors.response.use(function (res) {
  loading.close();
  return res.data;
}, function (err) {
  if (err.response && err.response.status != 200) {
    store.commit('user/setUser', []);
    var fullpath = encodeURIComponent(router.currentRoute.value.fullPath);
    router.push('/login?redirectUrl=' + fullpath);
  }
  loading.close();
  return Promise.reject(err);
});

// 请求工具函数
export default (function (url, method, submitData) {
  return instance(_defineProperty({
    url: url,
    method: method
  }, method.toLowerCase() === 'get' ? 'params' : 'data', submitData));
});