import { createRouter, createWebHashHistory } from 'vue-router'

const WEBSITEHOME = () => import('@/views/home')
const LAYOUT = () => import('@/views/layout')
const HOME = () => import('@/views/home/home')
const NEWS = () => import('@/views/news')
const NEWSDETAIL = () => import('@/views/news/detail')
const CITYS = () => import('@/views/citys')
const CITYSDETAILLAYOUT = () => import('@/views/citysHome/layout')
const CITYSINDEX = () => import('@/views/citysHome/index')
const CITYSTUI = () => import('@/views/citysHome/citysTui')
const ENTERPRISEZHAN = () => import('@/views/citysHome/enterpriseZhan')
const ENTERPRISEDETAIL = () => import('@/views/citysHome/enterpriseZhan/enterpriseDetail')
const FAMOUSGOODSZHAN = () => import('@/views/citysHome/famousGoodsZhan')
const CULTURALTOURISMZHAN = () => import('@/views/citysHome/culturalTourismZhan')
const CULTURALSCENICSPOT = () => import('@/views/citysHome/culturalTourismZhan/scenicSpotDetail')
const CULTURALTOURISMZI = () => import('@/views/citysHome/culturalTourismZhan/culturalZixunList')
const CULTURALTOURISMZHANDETAIL = () => import('@/views/citysHome/culturalTourismZhan/detail')
const REVITALIZEZHAN = () => import('@/views/citysHome/revitalizeZhan')
const REVITALIZEZHANDETAIL = () => import('@/views/citysHome/revitalizeZhan/detail')
const TAX = () => import('@/views/tax')

const routes = [
  {
    path: '/',
    name: 'websiteHome',
    component: WEBSITEHOME,
    meta: {
      title: '首页',
      pageTitle: '中蒙俄十五市盟陆海新通道RCEP及跨境电商进出口综合服务平台',
    },
    children: [
      {
        path: '/',
        name: 'layout',
        component: LAYOUT,
        meta: {
          title: '首页',
          pageTitle: '中蒙俄十五市盟陆海新通道RCEP及跨境电商进出口综合服务平台',
        },
        children: [
          {
            path: '/',
            name: 'home',
            component: HOME,
            meta: {
              title: '首页',
              pageTitle: '中蒙俄十五市盟陆海新通道RCEP及跨境电商进出口综合服务平台',
            },
          },
          {
            path: '/news',
            name: 'news',
            component: NEWS,
            meta: {
              title: '时政要闻',
              pageTitle: '中蒙俄十五市盟陆海新通道RCEP及跨境电商进出口综合服务平台',
              keepAlive: true
            },
          },
          {
            path: '/tax',
            name: 'tax',
            component: TAX,
            meta: {
              title: '税率查询',
              pageTitle: '中蒙俄十五市盟陆海新通道RCEP及跨境电商进出口综合服务平台',
            },
          },
          {
            path: '/newsDetail',
            name: 'newsDetail',
            component: NEWSDETAIL,
            meta: {
              pageTitle: '中蒙俄十五市盟陆海新通道RCEP及跨境电商进出口综合服务平台',
            }
          },
          {
            path: '/citys',
            name: 'about',
            component: CITYS,
            meta: {
              title: '城市馆',
              pageTitle: '陆海新通道联盟城市馆平台',
            },
          },
        ]
      },

    ]
  },

  {
    path: '/citysDetail',
    name: 'citysDetailsLayout',
    component: CITYSDETAILLAYOUT,
    meta: {
      pageTitle: '城市馆',
    },
    children: [
      {
        path: '/citysDetail',
        name: 'citysIndex',
        component: CITYSINDEX,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/citysTui',
        name: 'citysTui',
        component: CITYSTUI,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/enterpriseZhan',
        name: 'enterpriseZhan',
        component: ENTERPRISEZHAN,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/enterpriseDetail',
        name: 'enterpriseDetail',
        component: ENTERPRISEDETAIL,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/famousGoodsZhan',
        name: 'famousGoodsZhan',
        component: FAMOUSGOODSZHAN,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/culturalTourismZhan',
        name: 'culturalTourismZhan',
        component: CULTURALTOURISMZHAN,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/culturalScenicSpot',
        name: 'culturalScenicSpot',
        component: CULTURALSCENICSPOT,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/culturalTourismZhan/culturalTourismZi',
        name: 'culturalTourismZi',
        component: CULTURALTOURISMZI,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/culturalTourismZhan/culturalTourismZiDetail',
        name: 'culturalTourismZiDetail',
        component: CULTURALTOURISMZHANDETAIL,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/revitalizeZhan',
        name: 'revitalizeZhan',
        component: REVITALIZEZHAN,
        meta: {
          pageTitle: '城市馆',
        },
      },
      {
        path: '/revitalizeZhanDetail',
        name: 'revitalizeZhanDetail',
        component: REVITALIZEZHANDETAIL,
        meta: {
          pageTitle: '城市馆',
        },
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
// 百度统计代码
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?a82d59084b001b4a6678fb69f840e4ad";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();

router.afterEach((to, from, next) => {
  document.title = to.meta.pageTitle ? to.meta.pageTitle : '中蒙俄十五市盟陆海新通道RCEP';
})

router.afterEach((to, from) => {
  _hmt.push(['_trackPageview', to.fullPath]);
});
export default router
