import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入全局样式
import '@/assets/font/iconfont.css'
import '@/assets/style/common.css'

import '@vueup/vue-quill/dist/vue-quill.snow.css';

import 'animate.css';

const app = createApp(App)

app.use(store).use(router).mount('#app')

/* 自定义指令 实现图片安设计图中的宽高比例显示 */
app.directive('proportion', (el, bind) => {
    setTimeout(() => {
        const width = parseFloat(window.getComputedStyle(el).width)
        const height = width / bind.value
        el.style.height = height + 'px'
    }, 20)

    window.addEventListener('resize', function () {
        const width = parseFloat(window.getComputedStyle(el).width)
        const height = width / bind.value
        el.style.height = height + 'px'
    });
})

// 将统计代码加入到这个方法里面，这样每次router发生改变的时候都会执行一下统计代码
router.afterEach( ( to, from, next ) => {
    setTimeout(()=>{
            //百度统计
            var _hmt = _hmt || [];
            (function() {
                //每次执行前，先移除上次插入的代码
                document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?a82d59084b001b4a6678fb69f840e4ad";
                hm.id = "baidu_tj"
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
            })();
    },0);
} );