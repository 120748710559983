import _objectSpread from "E:/1-\u5DE5\u4F5C\u5907\u4EFD/5-\u9879\u76EE\u7BA1\u7406/18-\u5BC6\u8BC4\u9879\u76EE/0-\u9996\u9875\u9879\u76EE/code_shiwucheng/official_website/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  name: '',
  props: {},
  emits: [],
  components: {},
  setup: function setup(props, context) {
    var store = useStore();
    var router = useRouter();
    var data = reactive({});
    data.language = computed(function () {
      return store.state.language.language;
    });
    data.navList = computed(function () {
      return store.state.cate.navList;
    });
    data.navListIndex = computed({
      get: function get() {
        return store.state.cate.navListIndex;
      },
      set: function set(val) {
        store.commit('cate/setNavListIndex', val);
      }
    });
    return _objectSpread({}, toRefs(data));
  }
});