// 用户模块
import router from "@/router"
export default {
    namespaced: true,
    state() {
        return {
            navListIndex: 0,
            navList: [
                {
                    link: '/citysDetail',
                    name_c: '首页',
                    query: {
                        symbol: 'web',
                    }
                },
                {
                    link: '/citysTui',
                    name_c: '城市推介',
                    query: {
                        symbol: 'web',
                    }
                },
                {
                    link: '/enterpriseZhan',
                    name_c: '城市产业展区',
                    query: {
                        symbol: 'web',
                    }
                },
                {
                    link: '/famousGoodsZhan',
                    name_c: '城市采购专区',
                    query: {
                        symbol: 'web',
                    }
                },
                {
                    link: '/culturalTourismZhan',
                    name_c: '城市文旅展区',
                    query: {
                        symbol: 'web',
                    }
                },
                {
                    link: '/revitalizeZhan',
                    name_c: '城市特产专区',
                    query: {
                        symbol: 'web',
                    }
                }
            ]
        }
    },
    mutations: {
        setNavListIndex(state, index) {
            state.navListIndex = index
            router.push({
                path: state.navList[index].link,
                query: state.navList[index].query
            })

        },
    }
}